<template>
  <v-container>
    <error-dialog-component :model-value="this.errorDialog"></error-dialog-component>
    <v-row>
      <v-col>
        <span>Ostatnia modyfikacja statusu: {{ order.status.lastModified }}</span>
        <v-select
            :items="availableStatuses"
            v-model="currentStatus"
            @change="updateStatus()"
            item-text="name"
            item-value="id"
            label="Status"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <order-entities-details :model-value="order"></order-entities-details>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <order-client-details :model-value="order.client" :order-id="order.id"></order-client-details>
      </v-col>

      <v-col>
        <order-shipment-details
            :model-value="order.shipment"
            :order-id="order.id"
            :order-courier="order.orderCourier"
            :shipment-methods="shipmentMethods"
        ></order-shipment-details>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <order-invoice-details
            :model-value="order.invoice"
            :order-id="order.id"
            :order-visible-id="order.orderVisibleId"
        ></order-invoice-details>
      </v-col>

      <v-col>
        <order-metadata-details :model-value="order"></order-metadata-details>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <order-comments-details :comments="order.orderComments.commentEntities"></order-comments-details>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import OrderClientDetails from "@/components/orders/OrderClientDetails";
import OrderShipmentDetails from "@/components/orders/OrderShipmentDetails";
import OrderInvoiceDetails from "@/components/orders/OrderInvoiceDetails";
import OrderMetadataDetails from "@/components/orders/OrderMetadataDetails";
import OrderCommentsDetails from "@/components/orders/OrderCommentsDetails";
import OrderEntitiesDetails from "@/components/orders/entities/OrderEntitiesDetails";
import ErrorDialogComponent from "@/components/ErrorDialogComponent";
import {ordersApiClient} from "@/api/orders.api.client";
import {shipmentApiClient} from "@/api/shipment.api.client";

export default {
  name: 'OrderDetails',

  components: {
    ErrorDialogComponent,
    OrderEntitiesDetails,
    OrderCommentsDetails, OrderMetadataDetails, OrderInvoiceDetails, OrderShipmentDetails, OrderClientDetails
  },

  props: {
    orderId: String,
  },

  data() {
    return {
      order: {},
      shipmentMethods: [],
      availableStatuses: [],
      currentStatus: {
        name: String,

      },

      // MISC
      errorDialog: {
        errorMessage: "",
        visible: false
      }
    }
  },

  created() {
    this.getShipmentMethods()
    this.getOrder()
  },

  methods: {
    getShipmentMethods() {
      shipmentApiClient.getShipmentMethods(response => {
        this.shipmentMethods = response.data;
      }, error => {
        console.error(error)
      }, () => {
      });
    },

    getOrder() {
      ordersApiClient.getOrder(this.orderId, (orderResponse) => {
        this.order = orderResponse.data;
        this.getStatuses()
      }, error => {
        console.error(error)
      }, () => {
      });
    },

    getStatuses() {
      ordersApiClient.getExternalStatuses((response) => {
        this.availableStatuses = response.data;
        this.currentStatus = this.availableStatuses.find(status => status.name === this.order.status.statusName);
      }, error => {
        console.error(error)
      }, () => {
      });
    },

    updateStatus() {
      const data = {
        statusId: this.currentStatus
      }
      ordersApiClient.updateOrderStatus(this.order.id, data, () => {
      }, (error) => {
        this.errorDialog.visible = true;
        this.errorDialog.errorMessage = error.response.data.detail;
      }, error => {
        console.error(error)
      }, () => {
      });
    }
  }
};
</script>
