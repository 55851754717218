import {default as axios} from "axios";
import {ENTITIES_URL, ORDERS_URL} from "@/config/api.config";
import Vue from "vue";
import {ACCESS_TOKEN} from "@/api/auth.utils";

export class EntitiesApiClient {
    getBooksWithDates(from, to, successCallback, errorCallback, finallyCallback) {
        axios.get(ORDERS_URL + `/entities?from=${from}&to=${to}`, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response)
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    getBooksWithLimit(limit, successCallback, errorCallback, finallyCallback) {
        axios.get(ORDERS_URL + `/entities?limit=${limit}`, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response)
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    getBooksAssignedToMe(from, to, successCallback, errorCallback, finallyCallback) {
        axios.get(ORDERS_URL + `/entities/assigned?from=${from}&to=${to}`, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response)
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    jwbAssign(orderId, entityId, successCallback, errorCallback, finallyCallback) {
        axios.post(ORDERS_URL + `/${orderId}/entities/JWB/${entityId}/assign`, {}, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response)
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    jwbAssemble(orderId, entityId, successCallback, errorCallback, finallyCallback) {
        axios.post(ORDERS_URL + `/${orderId}/entities/JWB/${entityId}/assemble`, {}, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response)
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    verify(orderId, type, entityId, successCallback, errorCallback, finallyCallback) {
        axios.post(ORDERS_URL + `/${orderId}/entities/${type}/${entityId}/verify`, {}, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response)
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    updateDedication(orderId, entityId, dedicationText, successCallback, errorCallback, finallyCallback) {
        axios.put(ORDERS_URL + `/${orderId}/entities/${entityId}/dedication`, {"dedication": dedicationText}, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response)
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    getOrderEntityStatuses(productType, successCallback, errorCallback, finallyCallback) {
        axios.get(ENTITIES_URL + `/statuses/${productType}`, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response)
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    regenerateOrderEntity(orderId, entityId, successCallback, errorCallback, finallyCallback) {
        return axios.post(ORDERS_URL + `/${orderId}/entities/${entityId}/regenerate`, {}, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(err => {
            errorCallback(err);
        }).finally(() => finallyCallback());
    }

    getEntityGeneratedFiles(orderId, entityId, successCallback, errorCallback, finallyCallback) {
        return axios.get(ORDERS_URL + `/${orderId}/entities/${entityId}/files/generated`, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    getEntityGeneratedFile(orderId, entityId, fileId, successCallback, errorCallback, finallyCallback) {
        return axios.get(ORDERS_URL + `/${orderId}/entities/${entityId}/files/generated/${fileId}`, {
            responseType: 'blob',
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    getEntityGeneratedFileAsBase64(orderId, entityId, fileId, successCallback, errorCallback, finallyCallback) {
        return axios.get(ORDERS_URL + `/${orderId}/entities/${entityId}/files/generated/${fileId}/base64`, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    getEntityGeneratedFilesAsZip(orderId, entityId, successCallback, errorCallback, finallyCallback) {
        return axios.get(ORDERS_URL + `/${orderId}/entities/${entityId}/files/generated`, {
            responseType: 'blob',
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN),
                'Accept': 'application/zip'
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    postEntityGeneratedFile(orderId, entityId, formData, successCallback, errorCallback, finallyCallback) {
        return axios.post(ORDERS_URL + `/${orderId}/entities/${entityId}/files/generated`, formData, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    deleteEntityGeneratedFile(orderId, entityId, fileId, successCallback, errorCallback, finallyCallback) {
        return axios.delete(ORDERS_URL + `/${orderId}/entities/${entityId}/files/generated/${fileId}`, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    getUserImages(orderId, entityId, successCallback, errorCallback, finallyCallback) {
        return axios.get(ORDERS_URL + `/${orderId}/entities/${entityId}/user-image`, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(err => {
            errorCallback(err);
        }).finally(() => finallyCallback());
    }

    postUserImage(orderId, entityId, formData, successCallback, errorCallback, finallyCallback) {
        return axios.post(ORDERS_URL + `/${orderId}/entities/${entityId}/user-image`, formData, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(err => {
            errorCallback(err);
        }).finally(() => finallyCallback());
    }

    deleteUserImage(orderId, entityId, fileId, successCallback, errorCallback, finallyCallback) {
        return axios.delete(ORDERS_URL + `/${orderId}/entities/${entityId}/user-image/${fileId}`, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(err => {
            errorCallback(err);
        }).finally(() => finallyCallback());
    }

    updateUserImageName(orderId, entityId, data, successCallback, errorCallback, finallyCallback) {
        return axios.put(ORDERS_URL + `/${orderId}/entities/${entityId}/user-image`, data, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(err => {
            errorCallback(err);
        }).finally(() => finallyCallback());
    }
}

export const entitiesApiClient = new EntitiesApiClient();