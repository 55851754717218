<template>
  <div class="mainDiv">
    <spinner-component :loading="loading"></spinner-component>
    <error-dialog-component :model-value="this.errorDialog"></error-dialog-component>
    <test-mail-dialog :model-value="testMailTriggered"></test-mail-dialog>
    <image-selection-dialog :model-value="imageSelectionDialog"></image-selection-dialog>
    <newsletter-send-dialog :model-value="newsletterSendDialog"></newsletter-send-dialog>
    <v-snackbar v-model="newsletterSaved" timeout="3000">Zapisano</v-snackbar>
    <v-container>
      <v-row
          class="mx-4"
      >
        <v-col>
          <v-btn
              color="red"
              @click="goBack"
          >
            Wróć do listy
          </v-btn>
        </v-col>
      </v-row>
      <v-row
          class="mx-4"
      >
        <v-col
            cols="2"
        >
          <v-text-field
              v-model="template.name"
              label="Nazwa wzoru"
          ></v-text-field>
        </v-col>
        <v-col
            cols="6"
        >
          <div>
            <v-text-field
                v-model="template.subject"
                label="Tytuł maila"
                append-outer-icon="😃"
                @click:append-outer="toogleDialogEmoji"
            >
            </v-text-field>

            <VEmojiPicker
                v-show="showDialog"
                :style="{ width: '440px', height: '200', position: 'fixed', 'z-index': '1'}"
                labelSearch="Search"
                lang="pt-BR"
                @select="onSelectEmoji"
            />
          </div>

        </v-col>
      </v-row>
      <v-row
          class="mx-4"
      >
        <v-col cols="3">
          <v-btn
              @click="saveDesign"
          >
            Zapisz wzór
          </v-btn>
        </v-col>
        <v-col cols="3">
          <v-btn
              @click="exportHtml"
          >
            Eksportuj
          </v-btn>
        </v-col>
      </v-row>
      <v-row
          class="mx-4"
      >
        <v-col cols="3">
          <v-btn
              color="blue"
              @click="openTestEmailDialog"
          >
            Wyślij testowy mail
          </v-btn>
        </v-col>
        <v-col cols="3">
          <v-btn
              color="blue"
              @click="openNewsletterSendDialog"
          >
            Wyślij kampanie
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <div class="designEdit">
      <div class="container">
        <EmailEditor ref="emailEditor" v-on:load="editorLoaded" v-on:ready="editorReady"/>
      </div>
    </div>
  </div>
</template>

<script>

import SpinnerComponent from "@/components/SpinnerComponent";
import ErrorDialogComponent from "@/components/ErrorDialogComponent";
import TestMailDialog from "@/components/mails/TestMailDialog";
import {EmailEditor} from "vue-email-editor";
import ImageSelectionDialog from "@/components/mails/ImageSelectionDialog";
import {mailsApiClient} from "@/api/mails.api.client";
import {VEmojiPicker} from "v-emoji-picker";
import NewsletterSendDialog from "@/components/mails/NewsletterSendDialog.vue";

export default {
  name: 'EditNewsletterView',

  components: {
    NewsletterSendDialog,
    ImageSelectionDialog,
    EmailEditor, SpinnerComponent, ErrorDialogComponent,
    TestMailDialog,
    VEmojiPicker
  },

  props: {
    templateProp: Object
  },

  data() {
    return {
      // Email types
      emailTypes: [],

      template: {},

      // MISC
      loading: false,
      testMailTriggered: {
        templateId: null,
        visible: false
      },
      errorDialog: {
        errorMessage: "",
        visible: false
      },
      publishedSuccess: false,
      imageSelectionDialog: {
        visible: false,
        doneCallback: undefined
      },

      newsletterSendDialog: {
        visible: false,
        templateId: null,
      },

      showDialog: false,
      newsletterSaved: false
    }
  },

  created() {
    this.template = this.templateProp;
    this.loading = true;
  },

  methods: {
    editorReady() {
      this.loading = false;
      this.$refs.emailEditor.editor.registerCallback('selectImage', (data, done) => {
        this.imageSelectionDialog.doneCallback = done;
        this.imageSelectionDialog.visible = true;
      })
    },
    editorLoaded() {
      this.$refs.emailEditor.editor.loadDesign(this.template.design);
    },
    exportHtml() {
      this.$refs.emailEditor.editor.exportHtml((data) => {
        console.log(data.html);
      });
    },
    saveDesign() {
      this.$refs.emailEditor.editor.saveDesign(
          (design) => {
            this.$refs.emailEditor.editor.exportHtml(
                (data) => {
                  this.loading = true;
                  this.template.design = design;
                  this.template.html = data.html +
                      "<div style='text-align: center; font-size: 10px'>\n" +
                      "    Ta wiadomość została wysłana przez sklep internetowy www.jestemwbajce.pl \n" +
                      "    <br>należący do JWB Sp. z o.o. \n" +
                      "    <br>z siedzibą na Suchej 1a/36a w  Krakowie, \n" +
                      "    <br>NIP: 6762486317\n" +
                      "    <br>jednocześnie będącym administratorem Twoich danych osobowych.\n" +
                      "    <br>\n" +
                      "    <br>" +
                      "     <a th:href=\"@{https://apiv2.bookhero.me/v1/mails/lists/unsubscribe/{address}(address=${targetAddress})}\">Zrezygnuj z otrzymywania wiadomości</a>\n" +
                      "</div>";
                  mailsApiClient.updateNewsletter(this.template.id, this.template, () => {
                        this.newsletterSaved = true;
                      }, () => {
                        this.errorDialog.visible = true;
                        this.errorDialog.errorMessage = "Błąd podczas dodawania nowego template'u!";
                      }, () => this.loading = false
                  )
                }
            )

          }
      )
    },

    goBack() {
      this.$router.push('/newsletter-all')
    },

    toogleDialogEmoji() {
      this.showDialog = !this.showDialog;
    },
    onSelectEmoji(emoji) {
      this.template.subject += emoji.data;
      this.toogleDialogEmoji();
    },

    openTestEmailDialog() {
      this.testMailTriggered.visible = true;
      this.testMailTriggered.templateId = this.template.id;
    },

    openNewsletterSendDialog() {
      this.newsletterSendDialog.visible = true;
      this.newsletterSendDialog.templateId = this.template.id;
    }
  }
};
</script>
<style>
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.mainDiv {
  height: 100%;
}

#app, .mainDiv .designEdit {
  height: 100%;
}

.mainDiv .designEdit .container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
}

#bar {
  flex: 1;
  background-color: #40B883;
  color: #FFF;
  padding: 10px;
  display: flex;
  max-height: 40px;
}

#bar h1 {
  flex: 1;
  font-size: 16px;
  text-align: left;
}

#bar button {
  flex: 1;
  padding: 10px;
  margin-left: 10px;
  font-size: 14px;
  font-weight: bold;
  background-color: #000;
  color: #FFF;
  border: 0px;
  max-width: 150px;
  cursor: pointer;
}

#bar a {
  flex: 1;
  padding: 10px;
  margin-left: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  border: 0px;
  cursor: pointer;
  text-align: right;
  text-decoration: none;
  line-height: 160%;
}
</style>