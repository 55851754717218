import {default as axios} from "axios";
import {ORDERS_URL} from "@/config/api.config";
import Vue from "vue";
import {ACCESS_TOKEN} from "@/api/auth.utils";

export class OrderApiClient {
    getOrder(orderId, successCallback, errorCallback, finallyCallback) {
        axios.get(ORDERS_URL + `/${orderId}`, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response)
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    getOrdersByStatus(status, successCallback, errorCallback, finallyCallback) {
        axios.get(ORDERS_URL, {
            params: {
                status: status
            },
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    getOrdersByStatusWithLimit(status, limit, successCallback, errorCallback, finallyCallback) {
        axios.get(ORDERS_URL, {
            params: {
                status: status,
                limit: limit
            },
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    getOrdersByStatusWithDates(status, from, to, successCallback, errorCallback, finallyCallback) {
        axios.get(ORDERS_URL, {
            params: {
                status: status,
                from: from,
                to: to
            },
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    updateOrderStatus(orderId, data, successCallback, errorCallback) {
        axios.post(ORDERS_URL + `/${orderId}/status`, data, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response)
        }).catch(error => {
            errorCallback(error)
        });
    }

    updateClientData(orderId, data, successCallback, errorCallback, finallyCallback) {
        axios.put(ORDERS_URL + `/${orderId}/client`, data, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response)
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    updateShipmentData(orderId, data, successCallback, errorCallback, finallyCallback) {
        axios.put(ORDERS_URL + `/${orderId}/shipment`, data, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response)
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    updateInvoiceData(orderId, data, successCallback, errorCallback, finallyCallback) {
        axios.put(ORDERS_URL + `/${orderId}/invoice`, data, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response)
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    markAsPrinted(orderId, successCallback, errorCallback) {
        axios.post(ORDERS_URL + `/${orderId}/printed`, {}, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response)
        }).catch(error => {
            errorCallback(error)
        });
    }

    markAsFinished(orderId, successCallback, errorCallback) {
        axios.post(ORDERS_URL + `/${orderId}/finished`, {}, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response)
        }).catch(error => {
            errorCallback(error)
        });
    }

    getExternalStatuses(successCallback, errorCallback, finallyCallback) {
        axios.get(ORDERS_URL + `/statuses`, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response)
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    deleteOrder(id, successCallback, errorCallback, finallyCallback) {
        axios.delete(ORDERS_URL + `/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }
}

export const ordersApiClient = new OrderApiClient();