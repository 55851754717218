<template>
  <div>
    <spinner-component :loading="loading"></spinner-component>
    <error-dialog-component :model-value="this.errorDialog"></error-dialog-component>
    <v-card>
      <v-card-title>
        {{ entity.orderEntityVisibleId }} - {{ entity.product.title }}
      </v-card-title>
      <v-card-subtitle>
        <v-container>
          <v-row>
            <v-col
              cols="10"
            >
              <v-textarea
                  dense
                  :disabled="!isEditingDedication"
                  label="Dedykacja"
                  v-model="entity.dedication"
                  variant="outlined"
                  rows="3"
                  class="mx-2"
              ></v-textarea>
            </v-col>
            <v-col>
              <v-btn
                  color="primary"
                  dark
                  @click="isEditingDedication ? saveDedication() : toggleEditDedication()"
              >
                {{ isEditingDedication ? 'Zapisz' : 'Edytuj' }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              Płeć: <br> <h4>{{ entity.gender }}</h4>
            </v-col>
          </v-row>
        </v-container>
      </v-card-subtitle>
      <v-card-subtitle>
        Status: {{ normalizeStatus(entity.status.statusName) }}
        <div
            v-if="entity.status.statusName === 'ASSIGNED'"
        >
          Przypisano do: {{ entity.assignedTo }}
        </div>
      </v-card-subtitle>
      <v-card-actions>
        <v-btn
            color="primary"
            dark
            @click="assign()"
            v-if="entity.status.statusName === 'NEW'"
        >
          Przypisz
        </v-btn>
        <v-btn
            color="primary"
            dark
            @click="assembled()"
            v-if="entity.status.statusName === 'ASSIGNED'"
        >
          Potwierdź złożenie
        </v-btn>
        <v-btn
            color="primary"
            dark
            @click="verify()"
            v-if="order.status.statusName === 'TO_BE_APPROVED' && (entity.status.statusName === 'ASSEMBLED' && authUtils().requireRoles(['admin','internal']))"
        >
          Zweryfikuj
        </v-btn>
      </v-card-actions>
      <v-card-text>
        <v-img
            v-if="entity.product.previewImages.length > 0"
            :src="backend + '/v1/products/' + entity.product.id + '/images/' + entity.product.previewImages[0].id"
            :alt="backend + '/v1/products/' + entity.product.id + '/images/' + entity.product.images[0].id"
            height="100px"
            width="100px"
        ></v-img>
        <v-img v-else
               :src="backend + '/v1/products/' + entity.product.id + '/images/' + entity.product.images[0].id"
               height="100px"
               width="100px"
        >
        </v-img>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

import {computed} from "vue";
import SpinnerComponent from "@/components/SpinnerComponent";
import ErrorDialogComponent from "@/components/ErrorDialogComponent";
import {entitiesApiClient} from "@/api/entities.api.client";
import {authUtils} from "@/api/auth.utils";
import {normalizeStatus} from "@/utils/jwb-entity-status-utils";

export default {
  name: 'JWBOrderEntitiesDetails',
  inheritAttrs: false,
  components: {
    SpinnerComponent,
    ErrorDialogComponent
  },
  props: {
    manualStatuses: [],
    order: {
      type: Object,
      default: () => ({}),
    },
    entityModel: {
      type: Object,
      default: () => ({}),
    },
  },

  created() {
    console.log(this.order.status.statusName)
    console.log(this.entity.status.statusName)
    console.log(authUtils())
  },

  computed: {
    entity() {
      return this.entityModel ? this.entityModel : {}
    },
    backend() {
      return process.env.VUE_APP_BOOKHERO_API_PATH
    }
  },

  emits: ['update:entityModel'], // The component emits an event
  setup(props, {emit}) {
    const theModel = computed({  // Use computed to wrap the object
      get: () => props.entityModel,
      set: (value) => emit('update:entityModel', value),
    });

    return {theModel};
  },

  data() {
    return {
      formattedStatuses: {},

      // MISC
      loading: false,
      errorDialog: {
        errorMessage: "",
        visible: false
      },
      isEditingDedication: false // New data property
    }
  },

  methods: {
    normalizeStatus,
    authUtils() {
      return authUtils
    },
    fromStatusName(name) {
      return this.manualStatuses.filter(status => status.name === name)[0];
    },

    assign() {
      this.loading = true;
      entitiesApiClient.jwbAssign(this.order.id, this.entity.id, () => {
        this.entity.status.statusName = 'ASSIGNED';
      }, error => {
        this.errorDialog.visible = true;
        this.errorDialog.errorMessage = error.response.data.detail;
      }, () => this.loading = false);
    },

    assembled() {
      this.loading = true;
      entitiesApiClient.jwbAssemble(this.order.id, this.entity.id, () => {
        this.entity.status.statusName = 'ASSEMBLED';
      }, error => {
        this.errorDialog.visible = true;
        this.errorDialog.errorMessage = error.response.data.detail;
      }, () => this.loading = false);
    },

    verify() {
      this.loading = true;
      entitiesApiClient.verify(this.order.id, 'JWB', this.entity.id, () => {
        this.entity.status.statusName = 'VERIFIED';
      }, error => {
        this.errorDialog.visible = true;
        this.errorDialog.errorMessage = error.response.data.detail;
      }, () => this.loading = false);
    },

    toggleEditDedication() {
      this.isEditingDedication = !this.isEditingDedication;
    },

    saveDedication() {
      this.loading = true;
      entitiesApiClient.updateDedication(this.order.id, this.entity.id, this.entity.dedication, () => {
        this.isEditingDedication = false;
      }, error => {
        this.errorDialog.visible = true;
        this.errorDialog.errorMessage = error.response.data.detail;
      }, () => this.loading = false);
    }
  }
};
</script>
