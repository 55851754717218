<template>
  <v-container>
    <v-row>
      <v-col>
        <v-btn
            block
            color="primary"
            elevation="1"
            @click="backToList"
        >
          Wróć do listy
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
            block
            color="primary"
            elevation="1"
            @click="convertToCSV"
        >
          Zapisz jako CSV
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
            class="elevation-1"
            :headers="headers"
            :items="parsedCodes"
            :search="search"
        >
          <template v-slot:top>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                      v-model="search"
                      label="Szukaj"
                      class="mx-4"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-select
                      @change="updateCodesSelection"
                      :items="codeSelectItems"
                      label="Filtruj kody"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
                color="primary"
                dark
                @click="item.active ? deactivateCode(item) : activateCode(item)"
            >
              {{ item.active ? 'Dezaktywuj' : 'Aktywuj' }}
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

/* eslint-disable */
import {vouchersApiClient} from "@/api/vouchers.api.client";
import {saveAs} from "file-saver";

export default {
  name: 'VoucherCodesView',
  inheritAttrs: false,
  components: {},

  data() {
    return {
      headers: [
        {text: "Kod", value: 'code'},
        {text: "Ilość użyć", value: 'usage'},
        {text: "Kiedy ostatni raz użyto", value: 'lastUsed'},
        {text: "Status", value: 'status'},
        {text: "Akcje", sortable: false, value: 'actions'}
      ],
      originalCodes: [],
      parsedCodes: [],
      search: '',
      codeSelectItems: [
        'Wszystkie',
        "Użyte",
        "Nie użyte"
      ]
    }
  },

  created() {
    this.getVoucher(this.$route.params.id);
  },

  methods: {
    getVoucher(id) {
      this.loading = true;
      vouchersApiClient.getVoucher(id, (response) => {
        this.parsedCodes = [];
        this.originalCodes = response.data.codes;

        response.data.codes.forEach(code => {
          this.parsedCodes.push({
            code: code.code,
            usage: code.usageCount.usage,
            lastUsed: code.usageCount.lastUsed,
            status: code.active ? 'aktywny' : 'nieaktywny',
            active: code.active
          });
        })

      }, (error) => {
        this.snackbar = true;
        this.errorMessage = error.response.data.detail;
      }, () => this.loading = false)
    },

    backToList() {
      this.$router.push({name: 'vouchers-group', params: {groupType: this.$route.params.groupType}});
    },

    convertToCSV() {
      const array = [Object.keys(this.parsedCodes[0])].concat(this.parsedCodes)
      const converted = array.map(it => {
        return Object.values(it).toString()
      }).join('\n');
      const file = new Blob([converted], {type: 'text/csv'});

      saveAs(file, this.$route.params.id);
    },

    updateCodesSelection(item) {
      switch (item) {
        case 'Wszystkie':
          this.parsedCodes = this.filterCodes(null);
          break;
        case 'Użyte':
          this.parsedCodes = this.filterCodes(usage => usage > 0);
          break;
        case 'Nie użyte':
          this.parsedCodes = this.filterCodes(usage => usage === 0);
          break;
      }
    },

    activateCode(item) {
      this.loading = true;
      vouchersApiClient.activateCode(this.$route.params.id, item.code, () => {
        this.getVoucher(this.$route.params.id);
      }, (error) => {
        this.snackbar = true;
        this.errorMessage = error.response.data.detail;
      }, () => this.loading = false)
    },

    deactivateCode(item) {
      this.loading = true;
      vouchersApiClient.deactivateCode(this.$route.params.id, item.code, () => {
        this.getVoucher(this.$route.params.id);
      }, (error) => {
        this.snackbar = true;
        this.errorMessage = error.response.data.detail;
      }, () => this.loading = false)
    },

    filterCodes(predicate) {
      return this.originalCodes
          .filter(code => {
            if (predicate == null) {
              return true;
            }
            return predicate(code.usageCount.usage);
          })
          .map(code => {
            return {
              code: code.code,
              usage: code.usageCount.usage,
              lastUsed: code.usageCount.lastUsed
            };
          })
    }
  }
}
</script>
