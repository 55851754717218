<template>
  <v-container>
    <v-row>
      <v-col>
        <h4>Komentarze</h4>
      </v-col>
    </v-row>
    <v-row v-for="comment of comments" :key="comment">
      <v-card width="100%">
        <v-card-subtitle>{{ comment.sentAt }}</v-card-subtitle>
        <v-card-subtitle>{{ comment.user }}</v-card-subtitle>
        <v-card-text>{{ comment.content }}</v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'OrderCommentsDetails',
  inheritAttrs: false,
  components: {},
  props: {
    comments: Array,
  },

  data() {
    return {}
  },

  created() {
    console.log(this.comments)
  },

  methods: {}
};
</script>
